import React from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router } from 'next/router';
import Head from 'next/head';
import NProgress from 'nprogress';
import '@/styles/mixins.less';
import '@/styles/globals.less';
import '@/styles/fonts.css';
import 'nprogress/nprogress.css';
import { App as AntdApp } from 'antd';
import ConfigProvider from '@/context/ConfigProvider';
import withTheme from '@/theme';
import useScreenSizeStore from '@/zustand/screenSize';
import { useWindowSize } from 'react-use';

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const setScreenWidth = useScreenSizeStore((state) => state.setScreenWidth);
  const screenSize = useWindowSize();
  setScreenWidth(screenSize.width);
  return withTheme(
    <>
      <Head>
        <title>脉策科技</title>
        <meta name="description" content="脉策科技 desc" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <AntdApp>
        <ConfigProvider>{getLayout(<Component {...pageProps} />)}</ConfigProvider>
      </AntdApp>
    </>,
  );
}
