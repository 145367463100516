import { createContext, useCallback, useContext, useState } from 'react';
import { useWindowSize } from 'react-use';

type ProviderType = {
  isMobile?: boolean;
  screenSize?: {
    width: number;
    height: number;
  };
};
const Context = createContext<ProviderType>({});
const Provider = ({ children }: any) => {
  const screenSize = useWindowSize();
  const isMobile = screenSize.width < 900;
  const exposed = {
    isMobile,
    screenSize,
  };

  return <Context.Provider value={exposed}>{children}</Context.Provider>;
};

export const useConfigProvider = () => useContext(Context);

export default Provider;
