import React from 'react';
import { ConfigProvider } from 'antd';

const withTheme = (node: JSX.Element) => (
  <ConfigProvider
    prefixCls="ow"
    iconPrefixCls="mapstory-icon"
    theme={{
      token: {
        // colorPrimary: '#D2906A',
        fontSize: 13,
        colorLink: '#141414',
      },
      components: {},
    }}
  >
    <ConfigProvider>{node}</ConfigProvider>
  </ConfigProvider>
);

export default withTheme;
