import { create } from 'zustand';

interface CoreIndexStore {
  screenWidth: number;
  setScreenWidth: (width: number) => void;
  isMobile: () => boolean;
  isTablet: () => boolean;
  isDesktop: () => boolean;
}
const useScreenSizeStore = create<CoreIndexStore>((set, get) => ({
  screenWidth: 1440,
  setScreenWidth: (index: number) => set({ screenWidth: index }),
  isMobile: () => {
    return get().screenWidth < 768;
  },
  isTablet: () => {
    return get().screenWidth < 980;
  },
  isDesktop: () => {
    return get().screenWidth >= 980;
  },
}));

export default useScreenSizeStore;
